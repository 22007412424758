.redes-footer{
    margin-bottom: 10% !important;
    font-size: 60px !important;
    color: white;
}
.margin-footer{
    margin-top: 10% !important;
}
.box-footer{
    background-color: #732E1F;
    height: 100px;
    color: white !important;
}
.box-footer2{
    background-color: #732E1F;
    height: 60px;
    color: white !important;
}