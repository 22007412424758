.navbar{
    display: flex !important;
    justify-content: space-between !important;
    background-color: #57251a !important;
   
}

.lista{
    display: flex;
    list-style-type: none;
    align-items: center;
}

.dresscode{
    color: white;
    margin-left: -460% !important;

}

.logo{
    margin-top: 8% !important;
    width: 20% !important;
    margin-left: -150% !important;
}
.login{
    margin-left: 400% !important;
}
.logout{
    margin-left: 480% !important ;
    text-decoration: none;
    padding-bottom: 35% !important;
}
.link{
    list-style-type: none;
    text-decoration: none;
    color: white;
}
.accicon{
    padding-left: 1200% !important;
}