.navbar2 {
    background: #A1613E ;
    height: 80px;
    display: flex;
    justify-content: start;
    
    font-size: 1.5rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(12, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100vw;
    justify-content: center;
    
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}









