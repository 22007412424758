.text-decorator-none{
    text-decoration: none;
}
.botao-1{
    background-color: #013A20 !important;
    color: white !important;
}
.botao-2{
    background-color: #A1613E !important;
    color: white !important;
}
.back-categoria{
    background-color: #f2f2f2;
    height: 100vh;
}