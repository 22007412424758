.topo{
    padding: 20px;
    background-color: #f2f2f2 !important;
    height: 70vh;
    width: 100vh;
}

.finalizar{
    background-color: #013A20 !important;
    color: white !important;
}