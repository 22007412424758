.caixa-sobre{
    background-color: #f2f2f2 !important;
    padding: 3%;
    
}

@media only screen and ( max-width: 600px ){
    .caixa-sobre {
        padding: 7%;       
    }
}

.imagem-manifesto{
    background-repeat: no-repeat;
    min-height: 100% !important;
    background-size: cover;
    background-position: center;
    margin-bottom: 3% !important;
}

@media only screen and ( max-width: 600px ){
    .imagem-manifesto {
        height: 60%!important;        
    }
}

.py-box{
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    height: 500px;    
}

@media only screen and ( max-width: 600px ){
    .py-box {
        padding-top: 15%;
        padding-left: 5%;
        padding-right: 5%;    
    }
}

.titulo-manifesto{
    font-size: 40px !important;
    font-family: Stencil Std, fantasy !important;
    color: #223E1E;
}

@media only screen and ( max-width: 600px ){
    .titulo-manifesto {
        font-size: 85% !important;
        color: #223E1E; 
    }
}

.texto-manifesto{
    font-size: 18px !important;
    margin-top: 15px !important;
    font-family: 'Roboto', sans-serif !important;
    text-align: justify !important;
    
}

@media only screen and ( max-width: 600px ){
    .texto-manifesto {
        font-size: 70% !important;
        margin-top: 5% !important;
   }
}

.dresscodetxt{
    margin-top: 30px !important;
}



.linha{
    width: 100%;
    height: 100px;
}

.titulo-autores{
    font-size: 40px !important;
    padding-top: 7% !important;
}

@media only screen and ( max-width: 600px ){
    .titulo-autores{
        font-size: 100% !important;    
        padding-top: 50% !important;     
   }
}

.grid-card{
    direction: row !important;
    color: #F2F2F2;
}

.card{
    background-color: #ffffff !important;
    border-color: #D9C6BA !important;
    border-radius: 3px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
    width: 25%;
    margin: 1.5%;
    padding: 15px;
}

@media only screen and (max-width: 600px) {
   .card {
    width: 100%;
    }
}
.card-img{
    width: 150px;
    height: 150px;
    border-radius: 100%;
}

.nome-autor{
    font-family: 'Podkova', serif bold !important;
    color: #D9C6BA !important;
    font-style: bold !important;
}

.pitch-autor{
    font-size: 12px;
    font-family: 'Podkova', serif !important;
    color: #000000 !important;
    text-align: justify !important;
}
.github{
    width: 31px !important;
    height: 31px !important;
    color: #D9C6BA !important;
    margin-top: 14px !important;
}

.in{
    width: 40px !important;
    height: 40px !important;
    color: #D9C6BA !important;
    margin-top: 10px !important;
}