.imagem2{
    background-image: url(https://i.imgur.com/Q9FBfUo.jpg);
    background-repeat: no-repeat;
    width: 100vh;
    min-height: 130vh;
    background-size: cover;
    background-position: center;
}
.textos2{
    font-weight: bold !important;
}
.btnCancelar{
    margin-right: 20px !important;
    background-color: #1E1514 !important;
    
}
.btnCadastrar{
    background-color: #013A20 !important;
}
.text-decorator-none{
    text-decoration: none !important;
}
.inputs{
    border-color: #ECD5BB !important;
    background-color: #fff !important;
    
}
.background-color{
    background-color: #ECD5BB;
}